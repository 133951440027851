@import "colour";

.modalBox {
  // display: none;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $brand-black;
  background-color: rgba($brand-black, 0.4);
  opacity: 0;
  transition: all 0.3s;

  &.active {
    opacity: 1;
  }

  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 0 0.5rem;
    border-bottom: 1px solid $brand-shade2;

    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      font-size: 1.25rem;
    }
  }

  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.5rem 0 0;
    border-top: 1px solid $brand-shade2;

    & > * {
      margin: 0.25rem;
    }
  }

  .modal-content {
    background-color: $brand-shade1;
    margin: 15% auto;
    padding: 10px;
    border: 1px solid $brand-shade2;
    width: 80%;
    max-width: 500px;
  }

  .modal-close {
    float: right;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1;
    // color: $brand-white;
    padding: 0.2rem 0.6rem;
  }

  .confirmationInput {
    margin: auto;
    display: block;
  }

  .confirmationHelp {
    text-align: center;
    font-size: 0.9rem;
    color: $brand-shade3;
    margin-bottom: 0;
    margin-top: 5px;

    span {
      color: $brand-black;
      font-weight: bold;
      font-style: italic;
    }
  }
}
