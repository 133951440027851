@import "colour";
@import "typography";

@import "modals";

$transition-time: 0.2s;

// General
html {
  scroll-behavior: smooth;
}

body {
  background-color: $brand-white;
  color: $brand-black;
  font-family: "Helvetica Neue", "Arial", sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;

  content {
    display: block;
    min-height: calc(100vh - 50px - 56px);
    padding-top: 56px;
  }
}

:target {
  scroll-margin-top: 57px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  cursor: default;

  &.greyP {
    color: $brand-shade3;
  }
}

::selection {
  background: rgba(0, 0, 0, 0.99);
  color: $brand-white;
}

img {
  &.invertImg {
    filter: invert(1);
  }
}

// Tiles & Sections
div {
  &.tile {
    background-color: $brand-white;
    border-radius: 8px;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    margin: 20px;
    padding: 10px;
  }

  &.section {
    background-color: $brand-shade1;
    border-bottom: 1px solid $brand-shade2;
    border-top: 1px solid $brand-shade2;
  }
}

// Links
a {
  text-decoration: none;

  &.link {
    color: $brand-black !important;
    cursor: pointer !important;
    // font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}

// Buttons
button {
  transition: $transition-time;

  &.button-black {
    background-color: $brand-black;
    border: 1px solid $brand-black;
    border-radius: 6px;
    color: $brand-white;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 1.5rem;

    img {
      filter: invert(1);
    }

    &:hover {
      background-color: transparent;
      border: 1px solid $brand-black;
      color: $brand-black;

      img {
        filter: invert(0);
      }
    }

    &:disabled {
      background-color: $brand-shade4;
      border: 1px solid $brand-shade4;
      color: $brand-shade2;
      cursor: default;
    }

    &:hover:disabled img {
      filter: invert(0.8);
    }
  }

  &.button-white {
    background-color: $brand-white;
    border: 1px solid $brand-black;
    border-radius: 6px;
    color: $brand-black;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 1.5rem;

    &:hover {
      background-color: $brand-black;
      border: 1px solid $brand-black;
      color: $brand-white;

      img {
        filter: invert(1);
      }
    }

    &:disabled {
      background-color: transparent;
      border: 1px solid $brand-shade2;
      color: $brand-shade3;
      cursor: default;
    }
  }

  &.button-text-combo {
    background-color: $brand-black;
    border: 1px solid $brand-black;
    border-radius: 0 6px 6px 0;
    color: $brand-white;
    cursor: pointer;
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-left: 0;
    padding: 2px 6px;

    &:hover {
      background-color: transparent;
      border: 1px solid $brand-black;
      color: $brand-black;
    }

    &:disabled {
      background-color: $brand-shade4;
      border: 1px solid $brand-shade4;
      color: $brand-shade2;
      cursor: default;
    }
  }

  &.iconButton {
    img {
      height: 1.2rem;
      vertical-align: middle;
    }

    padding: 6px 10px;
  }

  &.smallIconButton {
    img {
      height: 0.8rem;
      vertical-align: middle;
    }

    padding: 2px 8px !important;
  }

  &:focus {
    outline: 0;
  }
}

// Form Items
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="fname"],
input[type="lname"],
input[type="number"],
input[type="name"],
input[type="date"],
input[type="datetime-local"],
input[type="time"],
select,
textarea {
  background-color: $brand-white;
  border: 1px solid $brand-shade2;
  border-radius: 6px;
  color: $brand-black;
  font-family: "Helvetica Neue", "Arial", sans-serif;
  padding: 5px;
  box-sizing: border-box;
  font-size: 1rem;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: $brand-shade3;
  }

  &:disabled {
    background-color: $brand-shade2;
  }

  &.red-border {
    border-color: $red-color;
  }

  &.button-text-combo {
    border-radius: 6px 0 0 6px;
    margin-right: -5px !important;
  }
}

// Badges
.blackBadge {
  background-color: $brand-black;
  border-radius: 5px;
  padding: 2px 8px;
  display: inline-block;
  margin-top: 4px;
  margin-right: 4px;
  max-width: 100%;

  p {
    color: $brand-white !important;
    margin: 0;
    overflow-wrap: break-word;
  }
}

.secondaryColor {
  color: $brand-shade4;
}

.smallFont {
  font-size: 0.85rem;
}

// Bootstrap fix
.row,
.form-row {
  margin-left: 0;
  margin-right: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-left: 0;
  padding-right: 0;
}

.hidden {
  display: none !important;
}
