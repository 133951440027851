@import "colour";
@import "bootstrap";
@import "main";
@import "navbar";
@import "footer";

body {
  text-align: center;

  h1 {
    margin-bottom: 3rem;
  }

  content {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 10px;
  }
}

#waiting-info,
#sending-info,
#help-info {
  p {
    font-size: 1.2rem;
  }
}

form {
  input,
  button {
    margin: auto;
    margin-bottom: 15px;
    text-align: center;
    width: 320px;

    &#emailInput {
      padding: 8px;
    }
  }

  #fehlermeldung {
    color: $red-color;
    margin-top: 0;
  }
}
