@import "colour";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

nav {
  backdrop-filter: saturate(180%) blur(5px);
  background-color: hsla(0, 0%, 100%, 0.8);
  border-bottom: solid 1px $brand-shade2;
  line-height: 30px;

  a {
    color: $brand-black !important;
    line-height: 30px;
  }
}

@include media-breakpoint-down(sm) {
  nav {
    // disable blur effect
    backdrop-filter: none;
    background-color: $brand-white;
  }
}
