h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  margin-bottom: 0.375rem;
  margin-top: 0;
}

h1 {
  font-size: 2.1rem;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1rem;
}
